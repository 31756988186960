import React from "react"
import moment from "moment"
import { graphql } from "gatsby"
import Layout from "../../components/layout";
import SEO from "../../components/seo";

export default function Template({data}) {
    const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark
    return (
        <Layout>
            <div className="blog-post-container">
                <div className="blog-post">
                    <h3>{frontmatter.title}</h3>
                    <h4>{moment(frontmatter.date).format('LL')}</h4>
                    <div
                        className="blog-post-content"
                        style={{
                          width: '708px',
                          fontSize: '16px',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '1.5rem'
                        }}
                        // max-width: 100%; width: 100%; white-space: pre-wrap; word-break: break-word; caret-color: rgb(55, 53, 47); padding: 3px 2px;
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                </div>
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date
        slug
        title
      }
    }
  }
`
